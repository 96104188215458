/* Reset */
@import url("https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css");

/* Noto Sans & Roboto Flex */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap');

/* Koumon*/
/*
@font-face {
  font-family: 'Koumon';
  src: url('../../public/fonts/Koumon Black.ttf') format('truetype');
  font-weight: 900
}
@font-face {
  font-family: 'Koumon';
  src: url('../../public/fonts/Koumon Extra Bold.ttf') format('truetype');
  font-weight: 800
}
@font-face {
  font-family: 'Koumon';
  src: url('../../public/fonts/Koumon Bold.ttf') format('truetype');
  font-weight: 700
}
@font-face {
  font-family: 'Koumon';
  src: url('../../public/fonts/Koumon Semi Bold.ttf') format('truetype');
  font-weight: 600
}
@font-face {
  font-family: 'Koumon';
  src: url('../../public/fonts/Koumon Medium.ttf') format('truetype');
  font-weight: 500
}
@font-face {
  font-family: 'Koumon';
  src: url('../../public/fonts/Koumon Regular.ttf') format('truetype');
  font-weight: 400
}
@font-face {
  font-family: 'Koumon';
  src: url('../../public/fonts/Koumon Light.ttf') format('truetype');
  font-weight: 300
}
@font-face {
  font-family: 'Koumon';
  src: url('../../public/fonts/Koumon Extra Light.ttf') format('truetype');
  font-weight: 200
}*/

h1, p {
  padding: 0;
  font-family: 'Noto Sans', 'Roboto Flex', sans-serif;
}

:root {
  /* text & bg colors */
  --primary_color: #FAFAFA;
  --bg_color: #202020;
  
  /* gradients */
  --grad_1: linear-gradient(158deg, #57EB80 10.62%, #EBD357 91.77%);
  --grad_2: linear-gradient(180deg, #EB5757 0%, #B357EB 100%);
  --grad_3: linear-gradient(98deg, #EB9557 24.69%, #B357EB 91.19%);
  --grad_4: linear-gradient(115deg, #EB9557 21.23%, #5792EB 116.89%);
  --grad_5: linear-gradient(118deg, #B357EB 7.36%, #57EB80 89.18%);
  --grad_6: linear-gradient(133deg, #57D9EB 24.86%, #EB5757 85.1%);
  --grad_7: linear-gradient(239deg, #5792EB 16.35%, #EBD357 81.61%);
  
  /* base colors */
  --red: #EB5757;
  --yellow:#EBD357;
  --orange:#EB9557;
  --green:#57EB80;
  --purple:#B357EB;
  --cyan:#57D9EB;
  --blue: #5792EB;

  /* FONTS */
  --primaryFont: 'Noto Sans', sans-serif;
  --secondaryFont: 'Roboto Flex', sans-serif;

  /* FONT SIZES */
  --fs-base: 16px;     /* Base font size */
  --fs-small: 0.875rem; /* Small text, like captions */
  --fs-normal: 1rem;   /* Normal body text */
  --fs-medium: 1.25rem; /* Slightly larger text */
  --fs-large: 1.5rem;   /* Larger text, like headings */
  --fs-xl: 2rem;        /* Extra-large text, for major headings */

  /* FONT WEIGHT */
  --fw_normal: 300;
  --fw_bold: 700;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Noto Sans', 'Roboto Flex',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  background-color: var(--bg_color);
  color: var(--primary_color);
}

#landscapeMessage {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

#landscapeMessage > p {
  font-family: 'Noto Sans', 'Roboto Flex', sans-serif;
  font-size: 2rem;
  font-weight: 300;
}

main {
 width: 100vw;
}

#scrollBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 2px;
  background-color: rgba(250, 250, 250, .5);
  backdrop-filter: blur(1px);
  z-index: 100;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px ;
}

section {
  padding: 3rem;
}


/* HOME */
#home_section {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}


/* COMPONENT SCROLL BUTTON*/
#scrollIcon {
  z-index: 12;
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  cursor: pointer;
}


/* NOISE BACKGROUND */
.noise_bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .03;/*.03;*/ /*.08*/
  background-image: url(../assets/noise_bg.gif);
  background-blend-mode: saturation;
  z-index: 3;
}


/* HERO */ 
#hero_div {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  letter-spacing: .125rem;
  padding-right: 3rem;
  /* FX TV */
  /* text-shadow: 0.06rem 0 0.06rem #ea36af, -0.125rem 0 0.06rem #75fa69;
  animation-duration: 0.1s;
  animation-name: textflicker;
  animation-iteration-count: infinite;
  animation-direction: alternate; */
}

#hero_div h1 {
  font-family: var(--primaryFont);
  font-size:clamp(3rem, 5vw, 10rem);
  font-weight: 200;
  line-height: 1;
}

#hero_div h4 {
  font-family: var(--primaryFont);
  font-size: clamp(1rem, 1.5vw, 8rem);
  font-weight: 300;
}

#myName {
  font-family: 'Koumon', 'Nato', sans-serif;
  font-weight: 900;
  font-size: clamp(1rem, 1.5vw, 8rem);
  letter-spacing: .125rem;
}

#myJob {

}

/* ABOUT */
#about_section {
  min-height: 100vh;
  position: relative;
}


/* CONTACT */
#contactMe_section {
  min-height: 100vh;
  position: relative;
}


/* TETRIS */
#tetris_div {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

/* TETRIS TOUCH CONTROLS */
#touchCTRL {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 50vh;
  left: 0vw;
  height: 50vh;
  width: 100vw;
  z-index: 13;
}

#areaDirection {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50vw;
}

#areaActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
}

#areaRight, #areaLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 25vw;
}

#areaRotate, #areaDown {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
  width: 50vw;
}

#areaRotate svg, #areaDown svg, #areaLeft svg, #areaRight svg{
  height: clamp(3rem, 10vw, 10rem);
  width: clamp(3rem, 10vw, 10rem);
}


/* TETRIS UI */
#gameBtn {
  /* position: absolute;
  top: 3rem;
  right: 3rem; */
  z-index: 13;
  cursor: pointer;
}

.gameboyOff {
  animation: shake-horizontal 5s linear 100;
}

@keyframes shake-horizontal {
			
  0%, 30%, 100% {
    transform:translateX(0);
  }
  12% {
    transform:translateX(-4px);
  }
  14% {
    transform:translateX(4px);
  }
  16% {
    transform:translateX(-4px);
  }
  18% {
    transform:translateX(4px);
  }
  20% {
    transform:translateX(-4px);
  }
  22% {
    transform:translateX(4px);
  }
  24% {
    transform:translateX(-4px);
  }
  26% {
    transform:translateX(2px);
  }
  28% {
    transform:translateX(-2px);
  }
}

#gameUI {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 8rem;
  right: 3rem;
  width: 200px;
  height: auto;
  border: 1px solid #FAFAFA;
  /*backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.05);*/
  border-radius: .5rem;
  padding:1rem 1rem;
  z-index: 4;
  font-weight: 900;
  /*FX TV*/
  /*text-shadow: 0.06rem 0 0.06rem #ea36af, -0.125rem 0 0.06rem #75fa69;
  animation-duration: 0.1s;
  animation-name: textflicker;
  animation-iteration-count: infinite;
  animation-direction: alternate;*/
}

#gameUI > p {
  display: inline;
  margin-bottom: 1rem;
  font-family: var(--primaryFont);
  font-size: clamp(1rem, 1.5vw, 8rem);
  font-weight: 300;
}

#gridBtnWrap, #colorBtnWrap {
  display: flex;
  gap: 1rem;
  width: 100%;
  z-index: 13;
  padding: .5rem 0;
  font-family: var(--primaryFont);
  font-size: clamp(1rem, 1.5vw, 8rem);
  font-weight: 300;
}

#gridBtn, #colorBtn {
  position: relative;
  border: 1px solid #fafafa;
  width: 3rem;
  height: 1.5rem;
  border-radius: 3rem;
  cursor: pointer;
}

#pauseBtn {
  border: 1px solid #fafafa;
  margin-top: 1rem;
  padding: .5rem;
  width: 100%;
  text-align: center;
  border-radius: 5rem;
  cursor: pointer;
  z-index: 14;
}



/* COMPONENT BUTTONS */
#slideBtn {
  position: relative;
  border: 1px solid #fafafa;
  width: 3rem;
  height: 1.5rem;
  border-radius: 3rem;
  cursor: pointer;
  font-family: var(--primaryFont);
  font-size: clamp(1rem, 1.5vw, 8rem);
  font-weight: 300;
}

.circleBtn {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #fafafa;
  border-radius: 3rem;
}



/* basic button */
#basicBtn {
  border: 1px solid #fafafa;
  margin-top: 1rem;
  padding: .5rem;
  width: 100%;
  text-align: center;
  border-radius: 5rem;
  cursor: pointer;
  z-index: 14;
  font-family: var(--primaryFont);
  font-size: clamp(1rem, 1.5vw, 8rem);
  font-weight: 300;
}

/*FX TV*/
/*@keyframes textflicker {
  from {
    text-shadow: 1px 0 0 #ea36af, -2px 0 0 #75fa69;
    box-shadow: .05px 0 0 #ea36af, -.5px 0 0 #75fa69;
  }
  to {
    text-shadow: 2px 0.5px 2px #ea36af, -1px -0.5px 2px #75fa69;
    box-shadow: .05px 0 0 #ea36af, -.5px 0 0 #75fa69;
  }
}*/


/* trick to force portrait orientation on mobile */

/*@media only screen and (max-device-width: 950px) and (orientation: landscape) {
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
}*/